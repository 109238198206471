*{
    box-sizing: border-box;
}

html,body,#root,.centralContainer{
    width: 100%;
    margin: 0px;
    padding: 0px;
    scroll-behavior: smooth;
}

.centralContainer {
    position: relative;
}