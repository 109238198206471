
.footerContainer{
    text-align: center;
    background-color: #EFEFEF;
    padding: 1.3rem;
    margin: 0px;
    font-size: 0.7rem;

    a{
        color: #33669A;
        padding: 0 5px;
        text-decoration: underline;
    }
}