@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400&display=swap');

.preAuthNav{
    padding: 0.5rem 15%;
    position: absolute;
    z-index: 2;
    background-color: rgba(0,0,0,0) !important;

    #basic-nav-dropdown{
        color: white !important;
    }

    .dropdown-menu{
        border-radius: 0px;
        padding: 0.25rem 0px;

        .dropdown-item {
             
            &:hover{
                color: white;
                background-color: #005B9C;
            }
        }
    }

    .globeLogo{
        vertical-align: text-top;
        margin-top: 0.15rem;
        width: 20px;
        height: 20px;
    }
}


.postAuthNav{
    padding: 0.5rem 15%;
    border-bottom: 1px solid #7FB539;
    box-shadow: 1px 1px 2px #7FB539;

    img{
        width: 80px;
        height: 40px;
    }

    #basic-nav-dropdown{
        color: darkslategrey;
    }

    .dropdown-menu{
        border-radius: 0px;
        padding: 0.25rem 0px;

        .dropdown-item {
             
            &:hover{
                color: white;
                background-color: #005B9C;
            }
        }
    }

    .navbar-collapse{
        .navbar-nav{
            .active{
                color: #005B9C;
                font-weight: 700;
            }
        
            .inactive{
                color: darkslategrey;
            }
        
            .logoutButton{
                border-radius: 2px;
                font-family: 'Work Sans', sans-serif;
                font-size: 0.85rem;
                font-weight: 300;
                width: 105px;
                padding-top: 4px;
                padding-bottom: 4px;
                color: white;
                background-color: #005B9C;
                border-color: #005B9C;

                &:hover{
                    background-color: white;
                    border-color: #005B9C;
                    color: #005B9C;
                }
            }
        }
    }

    .linkLogo{
        width: 10px;
        height: 10px;
        vertical-align: text-top;
    }

    .globeLogo{
        vertical-align: text-top;
        margin-top: 0.15rem;
        width: 20px;
        height: 20px;
    }
}

@media screen and (max-width: 1024px){

    .postAuthNav,.preAuthNav{
        padding: 0.5rem 7%;
    }

    .nav-link{
        padding: 0.5rem 0px;
    }
}


@media screen and (min-width: 1025px) and (max-width: 1366px){

    .postAuthNav,.preAuthNav{
        padding: 0.5rem 12%;
    }

    .nav-link{
        padding: 0.5rem 0.5rem;
    }
}