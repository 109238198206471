@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

.workdayContainer{
    text-align: center;

    .linkLogo{
        width: 12px;
        vertical-align: text-top;
        color: white;
    }

    a{
        text-decoration: underline;
    }

    .workdayTitleContainer{
        padding: 5% 0px;
        h2{
            font-family: 'Playfair Display', serif;
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }
        p{
            padding: 0 0.5rem;
        }
    }

    .guideContainer{
        color: white;
        background-color: #005B9C;
        text-align: start;
        h6{
            margin-top: 2rem;
            margin-bottom: 1.5rem;
            font-family: 'Playfair Display', serif;
            font-size: 2rem;
        }
        padding-bottom: 2rem;
        span{
            display: block;
            margin-bottom: 0.25rem;
        }
        ul {
            padding: 0px;
        }
        .guideLinks{
            display: inline-block;
            color: white;
            text-decoration: none;
            padding: 0.25rem 0px;
            &:hover{
                text-decoration: underline;
                color: rgb(233, 232, 232);
            }
        }
    }

    .workplaceCardContainer{
        border-top: 1px solid lightgrey;
        border-bottom: 1px solid lightgrey;
        padding: 3.5rem 0px;

        .workplaceImage{
            width: 170px;
            height: 70px;
            margin-bottom: 1.5rem;
        }

        .redirectButton{
            border-radius: 3px;
            font-size: 0.85rem;
            background-color: #005B9C;
            border-color: #005B9C;
            font-family: 'Work Sans', sans-serif;

            &:hover{
                background-color: #014677;
                border-color: #014677;
            }
        }
    }

    .redirectContainer{
        text-align: start;
        margin-left: 4%;
        margin-top: 4rem;
        margin-bottom: 2rem;
        button{
            border-radius: 3px;
            font-size: 0.85rem;
            font-family: 'Work Sans', sans-serif;
        }
    }
}

@media screen and (max-width: 640px){
    .workdayTitleContainer{
        padding: 10% 0px !important;
    }
}

@media screen and (min-width: 641px) and (max-width: 850px){
    .workdayTitleContainer{
        padding: 9% 0px !important;
    }
}

@media screen and (min-width: 851px) and (max-width: 1023px){
    .workdayTitleContainer{
        padding: 8% 0px !important;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1280px){
    .workdayTitleContainer{
        padding: 7% 0px !important;
    }
}

@media screen and (min-width: 1281px) and (max-width: 1366px){
    .workdayTitleContainer{
        padding: 6% 0px !important;
    }
}


@media screen and (max-width: 1024px){
    .workdayTitleContainer{
        h2{
            font-size: 2.5rem !important;
        }
    }

    .guideContainer{
        h6{
            font-size: 1.5rem !important;
        }
    }
}
