@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.contactsContainer{
    text-align: center;
    .titleContainer{
        padding: 6% 0px;
        padding-bottom: 3%;
        h2{
            font-family: 'Playfair Display', serif;
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }
        p{
            padding: 0 0.5rem;
        }
    }
    .searchContainer{
        border-radius: 3px;
        font-size: 0.9rem;
        background-color: #005B9C;
        border: 0.03rem solid;
        border-color: #005B9C;
        font-family: 'Work Sans', sans-serif;

        .buttons{
          border-radius: 3px;
          font-size: 0.85rem;
          background-color: #005B9C;
          border-color: #005B9C;
          font-family: 'Work Sans', sans-serif;

          &:hover{
              background-color: #014677;
              border-color: #014677;
          }
        }
    }
    .redirectContainer{
    text-align: start;
    margin-left: 4%;
    margin-top: 4rem;
    margin-bottom: 2rem;
        .button{
            border-radius: 3px;
            font-size: 0.85rem;
            font-family: 'Work Sans', sans-serif;
        }
    }
}

.cardContainer{
    
    padding-bottom: 4rem;

    .contactCard{
        margin-top:10%;
        
        .card-header{
            background-color: #005B9C;
            border-color: #005B9C;
            font-size: 1.35rem;
        }
        .card-body{
            background-color: #efefef;
            font-size: 0.85rem;
            border-top: 0rem solid;
            border-bottom: 0.15rem solid;
            border-right: 0.15rem solid;
            border-left: 0.15rem solid;
            border-color: #005B9C;
        }
    }
}

@media screen and (max-width: 1024px){
    .titleContainer{
        h2{
            font-size: 2.5rem !important;
        }
    }
}
  
@media screen and (max-width: 768px){
    .videoContainer{
        height: 180px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    .videoContainer{
        height: 200px !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1366px){
    .videoContainer{
        height: 220px !important;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px){
    .videoContainer{
        height: 240px !important;
    }
}