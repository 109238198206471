@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');

.homeContainer{
    text-align: center;
    .titleContainer{
        padding: 2% 0px;
        padding-bottom: 1%;
        h2{
            font-family: 'Playfair Display', serif;
            font-size: 3rem;
            margin-bottom: 0.5rem;
        }
        p{
            padding: 0 0.5rem;
        }
    }

    .cardContainer{
        padding-bottom: 4rem;
        display: flex;
        flex-wrap: wrap;

        .workdayCard,.adpCard,.benefitsCard{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 100%;
            margin-top: 3%;

            .card-body{
                background-color: #EFEFEF;
                font-size: 0.85rem;
                flex-grow: 1;
                button{
                    border-radius: 3px;
                    font-size: 0.7rem;
                    width: 40%;
                    margin: 0.5rem 0.6rem;
                }
    
                .btn-primary{
                    background-color: #005B9C;
                    border-color: #005B9C;
    
                    &:hover{
                        background-color: #014677;
                        border-color: #014677;
                    }
                }
            }

 
        }
    
        .workdayCard{
            .workdayLogo{
                width: 170px;
                height: 70px;
                margin: 3rem auto;
            }
        }

        .adpCard{
            .adpLogo{
                width: 150px;
                height: 70px;
                margin: 3rem auto;
            }
        }

        .benefitsCard{
            .benefitsTitle{
                width: 170px;
                height: 70px;
                margin: 3rem auto;
                color:#3071c7;
            }
        }
    }

    .youtubeContainer{
        background-color: #005B9C;
        padding: 0px;
        color: white;
        padding-top: 2.5rem;
        padding-bottom: 3rem;

        .videoContainer{
            width: 32%;
            height: 250px;
            min-width: 316px;
        }

        .dprYTlinkContainer{
            margin-top: 1rem;
            
            a{
                color: white;
                text-decoration: underline;

                &:hover{
                    color: lightgrey;
                }
            }
        }
    }

    .helpContainer{
        padding-bottom: 5rem;
        text-align: center;

        .helpCard{     
            margin-top: 4rem;       
            .iconContainer{
                height: 40px;
                width: 40px;
                background-color: #414042;
                border-radius: 50%;
                margin: 0 auto;
                padding-top: 0.25rem;
                .icon{
                    width: 23px;
                    height: 30px;
                    display: block;
                    margin: auto;
                }
            }
            .bookiconContainer{
                height: 40px;
                width: 40px;
                background-color: white;
                border-radius: 50%;
                margin: 0 auto;
                padding-top: 0.25rem;
                .bookicon{
                    width: 40px;
                    height: 35px;
                    display: block;
                    margin: auto;
                }
            }

            h6{
                margin-top: 0.5rem;
            }

            h7{
                color: #414042;
            }

            p{
                margin: 1rem 0;
            }
        }
    }

    .linkLogo{
        width: 12px;
        vertical-align: text-top;
        color: white;
    }
}

@media screen and (max-width: 1024px){
    .titleContainer{
        h2{
            font-size: 2.5rem !important;
        }
    }
}

@media screen and (max-width: 768px){
    .videoContainer{
        height: 180px !important;
    }
}

@media screen and (min-width: 769px) and (max-width: 1024px){
    .videoContainer{
        height: 200px !important;
    }
}

@media screen and (min-width: 1025px) and (max-width: 1366px){
    .videoContainer{
        height: 220px !important;
    }
}

@media screen and (min-width: 1367px) and (max-width: 1600px){
    .videoContainer{
        height: 240px !important;
    }
}