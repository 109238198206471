
.bannerContainer{
    text-align: center;
    justify-content: center;
    align-items: center;
    background-color: #EFEFEF;
    padding: 1.3rem;
    margin-bottom: 1rem;
    font-size: 1rem;
    width: fit-content;
    border-radius: 15px;


    .announcement-icon {
        font-size: 24px; 
        margin-right: 10px; 
        color:#005B9C;
    }
}