@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

.welcomeRootContainer{
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;

    .signInContainer{
        background: linear-gradient(360deg, hsla(0, 0%, 100%, 0) 0%, hsla(205, 99%, 26%, 0.9) 100%);
        width: inherit;
        height: 70%;
        position: relative;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .welcomeBackDropImage{
            position: absolute;
            z-index: -1;
            height: 100%;
            width: 100%;
            object-fit : cover;
        }

        .welcomeCardContainer{
            width: 20rem;
            margin: 6.5rem auto;
            text-align: center;
            border-radius: 2px;
            box-shadow: 2px 2px 4px black;

            .welcomeDRPLogo{
                width: 100px;
                height: 50px;
                margin: 2.2rem 0px;
                margin-left: 6.3rem;
            }

            .card-body{
                padding: 0px;
                padding-bottom: 5rem;

                .card-text{
                    line-height: 2.2rem;
                    margin-bottom: 2rem;
                }

                span{
                    font-family: 'Playfair Display', serif;
                    font-size: 3rem;
                }

                button{
                    background-color: hsla(205, 99%, 26%, 1);
                    width: 45%;
                    border-radius: 2px;
                    border : 1px solid hsla(205, 99%, 26%, 1);
                    box-shadow: 1px 1px 2px hsla(205, 99%, 26%, 0.9);
                    font-family: 'Work Sans', sans-serif;

                    &:hover{
                        background-color: #014677;
                        border-color: #014677;
                        box-shadow: 1px 1px 2px #014677;
                    }
                }
            }
        }
    }

    .justify-content-center{
        padding-bottom: 5rem;
        text-align: center;

        .assistContainer{     
            margin-top: 4rem;       
            .iconContainer{
                height: 40px;
                width: 40px;
                background-color: #414042;
                border-radius: 50%;
                margin: 0 auto;
                padding-top: 0.25rem;
    
                .icon{
                    width: 30px;
                    height: 30px;
                    display: block;
                    margin: auto;
                }

                .iconHelp{
                    width: 23px;
                }
            }

            h6{
                margin-top: 0.5rem;
            }

            h7{
                color: #414042;
            }

            p{
                margin: 1rem 0;
            }
        }
    }
}